import './src/scss/app.scss';

// Add smooth scroll to all pages
import smoothscroll from 'smooth-scroll';

if (window) {
  smoothscroll('[data-smooth-scroll]');
}

// Track the build info of the current instance
if (process.env.GATSBY_VERSION) {
  console.log('build: ' + process.env.GATSBY_VERSION);
}
